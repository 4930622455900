.container {
    box-shadow: 0px 0px 7px rgb(0 0 0 / 29%);
    border-radius: 5px;
    padding: 15px;
    margin-bottom:15px;
  }

  .Ebooks_main{
    display: flex;
    justify-content: space-between;
  }

  .head{
    font-size: 25px;
  }

  .subHead{
    font-size: 19px;
    font-weight: bold;
  }

  .imgLogo{
    position: absolute;
    right: 14px;
    top: 10px;
    width: 30px;
  }
  
  .imageContainer {
    width: 8%;
  }
  
  .mainImage {
    width: 100%;
  }

  .cardMind{
    width: 95%;
    /* width: 100%; */
    position: relative;
    box-shadow: rgb(191, 176, 176) 2px 0px 4px 0px;
    cursor: pointer;
    /* background-size: 100% 100%; */
    height: 250px;
    margin: 10px;
    border-radius: 12px;
    overflow: hidden;
  }
  
  .cardContainer {
    display: flex;
    align-items: center;
  }
  
  .dateContainer {
    margin-top: 10px;
  }
  
  .studentsAttempted {
    background: #69d96d;
    border: 1px solid rgba(105, 217, 109, 0.55);
    border-radius: 10px;
    padding: 8px;
    margin: 0px;
    margin-top:10px;
    color:white;
  }
  
  .infoflexCon {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding: 0px 10px;
  }
  
  .infoHeader {
    font-weight: 500;
    font-size: 18px;
  }
  
  .textColor {
    color: #1E1D1D;
  }
  
  .imgCard{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .BooksContainer{
    padding: 50px 35px;
    margin-top: 25px;
  }

  .btns{
    font-size: 15px !important;
    width: 90px !important;
    background-color: #216E91 !important;
    color: white !important;
    margin-top: 20px !important;
  }

  .btns:hover{
    color: #1E1D1D !important;
  }