 

.whiteColor{
    /* color: white; */
}

.headingText{
    font-size: 22px;
    font-weight: 600px;
}

.subContainer{
    /* background-image: url('../../Assets/Onboarding/edOnboard.png'); */
    background-size: 100% 100%;
    /* height: 100vh; */
}

.heading{
    display: flex;
    align-items: center;
    max-width: fit-content;
    padding: 30px;
    /* color: white; */
    font-size: 24px;
}

.centerContainer{
    /* max-width: 1400px; */
    margin: 80px 10px 0;
    text-align: center;
    /*margin-top: 8%; */
}

.selectionHeading{
    /* color: white; */
    margin-bottom: 30px;
}

.boards{
    display: flex;
    /* justify-content: space-between; */
    gap: 40px;
}

.gradeClass{
    width: 300px;
}

.classes{
    width: 60%;
}

.containerBoard{
    width: 200px;
    border: 1px solid #000;
    padding: 20px;
    border-radius: 12px;
    /* color: white; */
    cursor: pointer;
}

.boardselect{
    background-color: #000;
    color: pink;
}

.btns {
    /* position: absolute; */
    /* bottom: 41px; */
    /* right: 75px; */
    margin-top: 40px;
    padding: 14px;
    width: 150px;
    border: none;
    border-radius: 12px;
    background-color: #118a8a;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }

.textBox{
    margin: 10px;
    width: 100px;
    margin-right: 20%;    
}

.classContainer{
    display: flex;
    margin-bottom: 20px;
}

.classData{
    /* border: 1px solid #000; */
    white-space: nowrap;
    margin: 0 10px 10px;
    padding: 10px;
    font-size: 20px;
    border-radius: 3px;
    /* background: white;
    color: #cf9ca5; */
}

.classData:first-child{
    margin-left: 0px;
}

.textfields{
    /* color: white; */
}

.textfields:hover{
    border: none;
}