.divider {
  border-bottom: 1px solid black;
  /* margin-left: 5px;
    margin-right: 5px; */
}

.flexFields {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 10px;
}

.displaytrue {
  display: block;
  position: absolute;
  background-color: white;
  color: black;
}
.displayNone {
  display: none;
}

.btnUpload {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.uploadBtnFlex{
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}