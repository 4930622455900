.content{
    display: flex;
    margin-bottom: 25px;
}

.content > .boards:first-child{
    border-left: none;
    margin-left: 0;
    padding-left: 0;
}

.boards{
    font-size: 25px;
    margin: 0 5px;
    padding: 0 10px;
    border-left: 3px solid rgb(112, 105, 105);
}

.editIcon{
    width: 28px !important;
    height: 28px !important;
    margin-top: 6px;
    cursor: pointer;
    background-color: white;
    padding: 3px;
    border-radius: 50%;
    /* border: 1px solid; */
    box-shadow: 1px 2px 20px #b19e9e;
}