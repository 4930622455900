.con {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dropBoxStyles {
  width: 100%;

  border: 2px dotted #e1f3fb;

  height: 200px;

  background-color: #e1f3fb;

  display: flex;

  align-items: center;

  justify-content: center;
  margin-bottom: 10px;
}
.flexStyleDropBox {
  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;
}

.cardContainer {
  /* border: 1px solid red; */
  padding: 20px;
  box-sizing: border-box;
}
.showCard {
  border: 1px solid rgb(80, 79, 79);
  border-radius: 4px;
  padding: 20px;
}

/* fonts */
.editStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* fonts */

.font20 {
  font-size: 18px;
  font-weight: 550;
  text-transform: capitalize;
}
.micCon {
  cursor: pointer;
}
.top {
  width: 400px;
}

.shwostudentdata {
    background-color: #DFF6FF;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.idtext{
    background-color: #032C6B;
    color: #fff;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 400;
}
