.divider {
  border-bottom: 1px solid black;
  /* margin-left: 5px;
      margin-right: 5px; */
}

.flexFields {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  margin-bottom: 10px;
  padding-left: 5%;
  padding-right: 5%;
  gap: 10px;
}

.displaytrue {
  display: block;
  position: absolute;
  background-color: white;
  color: black;
}
.displayNone {
  display: none;
}

.dropBoxStyles {
  width: 100%;

  border: 2px dotted #e1f3fb;

  height: 200px;

  background-color: #e1f3fb;

  display: flex;

  align-items: center;

  justify-content: center;
  margin-bottom: 10px;
}

.flexStyleDropBox {
  display: flex;

  flex-direction: column;

  justify-content: center;

  align-items: center;
}

.btnClasses {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding-left: 3%;
  margin-bottom: 10px;
  padding-right: 3%;
}

/*  */

.drawerHeading {
  font-size: 20px;
  padding: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}

/* styles from add test/edit */

.aptitudePop {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

/* button align center */

.alignCenter {
  text-align: center;
  margin-bottom: 10px;
}
