.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  background: #c3c3c3 !important;
}

/* .mySwiper{
   position: relative;
  }
  .sowstatus{
    position: absolute;
    width: 100%;
    color: black;
    bottom: 10%;
    right: 10%;
  } */
.container {
  position: relative;
}

.container::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.4);
  border-radius: 10px;
}

.onslidetext {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  z-index: 4;
}

.customArrow {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  cursor: pointer;
}

.customArrow:hover {
  background: rgba(0, 0, 0, 0.7);
}

.customPrevArrow {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.customNextArrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}