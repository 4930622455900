.addContainer {
  /* border: 1px solid black; */
  padding: 25px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.titleStyles {
  font-size: 18px;
  font-weight: 600;
  color: #118a8a;
}

.filterBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btnFlex {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.formBox {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-top: 5px;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
}

/* table styles */

.tableBox {
  margin-top: 15px;
}
