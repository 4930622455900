.studentInfoBox {
  background-color: #f6fbff;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
}

.studentname {
  margin: 5px 0;
  color: #062e6c;
  font-size: 20px;
  font-weight: 500;
}

.schoolname {
  margin: 5px 0;
  font-size: 12px;
  color: #000;
  font-weight: 500;
}

.class {
  margin: 5px 0;
  font-size: 12px;
  font-weight: 400;
  color: #747474;
}

.tableheading {
  background-color: #f6fbff;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  margin: 10px 0;
}

.tableheading2 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
  margin: 10px 0;
  border: 1px solid #fff;
  align-items: center;
}

.col1 {
  width: 45%;
  margin: 0;
}
.col2 {
  width: 20%;
  margin: 0;
}
.col3 {
  width: 20%;
  margin: 0;
}

.productIMgBox {
  width: 84px;
  height: 84px;
  border-radius: 5px;
  overflow: hidden;
  & img {
    width: 100%;
    height: 100%;
  }
}

.productBox {
  display: flex;
  gap: 10px;
  align-items: center;
}

.productName {
  margin: 5px 0;
}

.quantityBtns {
  display: flex;
  border: 1px solid #11888a;
  justify-content: space-between;
  border-radius: 5px;
}

.Buttons {
  color: #fff;
  background: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%);
  width: 30px;
  text-align: center;
}

.MRP {
  color: #989898;
  text-decoration: line-through;
  font-size: 12px;
  font-weight: 400;
  margin: 5px 0;
}

.selling_price {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0;
}

.selectedProduct {
  /* background-color: #e0f7fa; Light blue background */
  border: 1px solid #00796b; /* Dark teal border */
  transition: background-color 0.3s ease;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.productTimeDe {
  margin: 5px 0;
}

.Booknow {
  width: 100% !important;
  background: linear-gradient(
    97.14deg,
    #11888a -3.11%,
    #2a5e95 100%
  ) !important;
}

.ApplyBtn {
  /* width: 100% !important; */
  background: linear-gradient(
    97.14deg,
    #11888a -3.11%,
    #2a5e95 100%
  ) !important;
}
.inputField{
    margin: 20px 0;
    width: 100%;
}

.inputInner{
    width: 100% !important;
}
/* file design */

.con {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dropBoxStyles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.flexStyleDropBox {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
}

.cardContainer {
  /* border: 1px solid red; */
  padding: 20px;
  box-sizing: border-box;
}
.showCard {
  border: 1px solid rgb(80, 79, 79);
  border-radius: 4px;
  padding: 20px;
}

/* fonts */
.editStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* fonts */

.font20 {
  font-size: 18px;
  font-weight: 550;
  text-transform: capitalize;
}
.micCon {
  cursor: pointer;
}
.top {
  /* width: 400px; */
  border: 1px dashed #032c6b;
  margin: 20px 0;
}


.couponinputField{
  margin: 10px 0;
  display: flex;
  gap: 20px;
}