

.infoText{
    color: #959191;
}
.sepreation{
    display: flex;
    justify-content: space-between;
}
.sepreation > h4{
    text-align: center;
    width: 50%;
}
.innerSeprate{
    width: 50%;
}