.divider {
  border-bottom: 1px solid black;
  /* margin-left: 5px;
    margin-right: 5px; */
}

.flexFields {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: row;
  margin-bottom: 10px;
  padding-left: 5%;
  padding-right: 5%;
  gap: 10px;
}

.displaytrue {
  display: block;
  position: absolute;
  background-color: white;
  color: black;
}
.displayNone {
  display: none;
}

.btnClasses {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding-left: 3%;
  padding-right: 3%;
}
