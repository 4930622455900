.container {
  box-shadow: 0px 0px 7px rgb(0 0 0 / 29%);
  border-radius: 5px;
  padding: 15px;
  margin-bottom:15px;
  background-color: white;
}

.imageContainer {
  width: 8%;
}

.mainImage {
  width: 100%;
}

.cardContainer {
  display: flex;
  align-items: center;
}

.dateContainer {
  margin-top: 10px;
}

.studentsAttempted {
  background: #69d96d;
  border: 1px solid rgba(105, 217, 109, 0.55);
  border-radius: 10px;
  padding: 5px;
  margin: 0px;
  margin-top:10px;
  color:white;
  width: max-content;
}


.assignedtoStudents{
  background: #FF9E00;
  width: max-content;
  border-radius: 10px;
  padding: 5px;
  margin: 0px;
  margin-top:10px;
  color:white;
}

.pending{
  background: #FFC7CE;
  width: max-content;
  border-radius: 10px;
  padding: 5px;
  margin: 0px;
  margin-top:10px;
  color: #9C0006;

}

.infoflexCon {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  padding: 0px 10px;
}

.infoHeader {
  font-weight: 500;
  font-size: 18px;

}

.textColor {
  color: rgba(0, 0, 0, 0.25);

}
