.studentInfoBox {
    background-color: #f6fbff;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 40px;
  }
  
  .studentname {
    margin: 5px 0;
    color: #062e6c;
    font-size: 20px;
    font-weight: 500;
  }
  
  .schoolname {
    margin: 5px 0;
    font-size: 12px;
    color: #000;
    font-weight: 500;
  }
  
  .class {
    margin: 5px 0;
    font-size: 12px;
    font-weight: 400;
    color: #747474;
  }