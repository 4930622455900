.filterBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .btnFlex {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
  }
  .tableBox {
    margin-top: 15px;
  }