.testBox{
    /* border: 1px solid red; */
    padding: 5px;
}

.testFlex{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red; */
}


.font20{
    font-size: 22px;
    font-weight: bold;
}

.font18{
    font-size: 18px;
    font-weight: 600;
}